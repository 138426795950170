import React from 'react';
import { Table, Input, Button, Space, Tag } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { RecievesTypes } from '../../interfaces/order';
import './style.css';
type MyState = {
  searchText: string;
  searchedColumn: number;
};
type MyFilterDropdown = {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
};
class DataTable extends React.Component<RecievesTypes, MyState> {
  state: MyState = {
    searchText: '',
    searchedColumn: null || 0,
  };

  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            if (this.searchInput) {
              this.searchInput = node;
            }
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: number) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput && this.searchInput.select(), 100);
      }
    },
    render: (text: string) =>
      this.state.searchedColumn === dataIndex ? (
        
        text
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: number) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  searchInput: any;

  render() {
    let data: any = [];
    let reportData: any = [];
    if (this.props?.report.length > 0) {
      reportData = this.props.report;
      // console.log("reportData# 130: ", reportData)
      reportData.forEach( (eachRow: any, index: any ) => {

        let eachItem: any = {}
        eachItem["order_number"] = eachRow.Amazon_order_number
        eachItem["imei"] = eachRow.IMEI
        eachItem["code"] = eachRow.response.code
        eachItem["status"] = eachRow.response.code === 3000 ? eachRow.response.message : eachRow.response.error
        // eachItem["status"] = eachRow.response.error
        
        
        data.push( eachItem )
          
      });
      // console.log("data: ", data);
    }
    
    const columnsCreatorOrdersReport: any = [
      
      {
        title: 'Order Number',
        dataIndex: 'order_number',
        key: 'order_number',
        width: '25%',
        // ...this.getColumnSearchProps('item_name'),
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '15%',
        // ...this.getColumnSearchProps('item_name'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '60%',
        // ...this.getColumnSearchProps('sku'),
      },
      
    ];
    
    const columnsInboundReceivingsReport: any = [
      {
        title: 'IMEI',
        dataIndex: 'imei',
        key: 'imei',
        width: '25%',
        // ...this.getColumnSearchProps('item_name'),
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '15%',
        // ...this.getColumnSearchProps('item_name'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '60%',
        // ...this.getColumnSearchProps('sku'),
      },
      
    ];

    return (
      <>
        <Table
          columns={

            this.props.reportNo == 'inbound-receivings-processing'
              ? columnsInboundReceivingsReport
              : columnsCreatorOrdersReport


            // this.props.reportNo === 'rma'
            //   ? columnsRMAReport
            //   : this.props?.reportNo === 'order'
            //   ? columnsOrderReport
            //   : this.props.reportNo === 'instock_all'
            //   ? columnsAllInStockReport
            //   : this.props.reportNo === 'adjustments'
            //   ? columnsAdjustmentReport
            //   : columnsInStockReport
          }
          dataSource={data}
          scroll={{ x: 1300 }}
        />
      </>
    );
  }
}

export default DataTable;
