import { useState } from 'react';
import { Form, Input, Button, Row } from 'antd';
import { LoginTypes } from '../../interfaces/order';
// import Logo from '../../assets/logov1.png';
import './styles.scss';
let Logo = require('../../assets/logov1.png');

const FormItem = Form.Item;

const Login: React.FC<LoginTypes> = (props) => {
  const { login } = props;
  const [error, setError] = useState<string>('');
  const onFinish = (values: LoginTypes) => {
    login && login(values);
    console.log("Login Values: ", values)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div id="login">
      <div className={'form'}>
        <div className={'logo'}>
          <img alt="logo" src={Logo} />
        </div>
        <h2 style={{ textAlign: 'center' }}>Report Panel</h2>
        <p style={{ color: 'red' }}>{error}</p>
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <FormItem
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
            hasFeedback
          >
            <Input type="email" placeholder={'Email'} />
          </FormItem>
          <FormItem
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            hasFeedback
          >
            <Input.Password type="password" placeholder={'Password'} />
          </FormItem>
          <FormItem
            name="loginFrom"
            initialValue={'ecommerce'}
            hidden={true}
          >
            <Input type="hidden" placeholder={'loginFrom'} />
          </FormItem>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              //loading={loading.effects.login}
            >
              Login
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Login;
