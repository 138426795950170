export {};

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export function removeUnmatched(previousArray, newArray) {
  console.log(previousArray);
  const thirdArray = previousArray.filter(function (temp) {
    return newArray.some(function (temp2) {
      // stop and return true at first match
      return temp2.Product === temp.Product;
    });
  });
  return thirdArray;
}
