import React from 'react';
import { APIDetailsHeaderTypes } from '../../interfaces/order';
import './styles.scss';
const Landing: React.FC<APIDetailsHeaderTypes> = (props) => {
  return (
    <div className="right_side_header">
      <span className="right_side_header_all_api_calls">
        Total API Calls : <span>{props.allAPINo}</span>
      </span>
      <span className="right_side_header_success_api_calls">
        Success API Calls : <span>{props.successAPINo}</span>
      </span>
      <span className="right_side_header_failed_api_calls">
        Failed API Calls : <span>{props.failedAPINo}</span>
      </span>
    </div>
  );
};

export default Landing;
