import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Alert, Tooltip, message, Select } from 'antd';
import './styles.scss';
import { AlertsTypes, ParentTypes } from '../../interfaces/panel';
import DataTable from '../../components/Report/data.table';


import {
  SyncOutlined,
  LoadingOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { isEmpty } from '../../utils/methods.array';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

const { Option } = Select;

const Excel = require('../../assets/excel.png');

const successAlert = (alert: string) => {
  message.success(alert);
};
const errorAlert = (alert: string) => {
  message.error(alert);
};
const warningAlert = (alert: string) => {
  message.warning(alert);
};
const text = (
  <span>
    Pleease upload orders file to update on
    <code
      style={{
        color: 'white',
        background: 'black',
        padding: '4px',
        borderRadius: '3px',
        fontSize: '12px',
      }}
    >
      Zoho Creator
    </code>{' '}
    
  </span>
);
const allowedTypes = new Set([
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]);
const defaultAlerts: AlertsTypes[] = [
  {
    for: 'order',
    message: 'Please wait, inbound receivings updation process is in progress...',
    type: 'info',
  },
  // {
  //   for: 'package',
  //   message: 'Package And Imei Out Creation In Process...',
  //   type: 'info',
  // },
];
const Index: React.FC<ParentTypes> = (props) => {
  // const { customers, customersDetail } = props;
  const [startCreation, setStartCreation] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertsTypes[]>(defaultAlerts);
  const [alertIcon, setAlertIcon] = useState<string>('loading');
  
  const [hitReport, setHitReport] = useState<any>(false);
  const [orderReport, setOrderReport] = useState<any>([]);
  const [reportName, setReportName] = useState<string>('orders-processing');
    

  useEffect(() => {
    // customers();
  }, []);
  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e: any) => {

    e.preventDefault();
    e.stopPropagation();
  
    updateInboundReceivings(e.dataTransfer.files[0]);
  };

  const generateXLSX = ( props: any ) => {

    /*const element = document.createElement("a");
    const file = new Blob( [ JSON.stringify(profitReport) ] , {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
    */

    
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = "inbound-receivings";
    
    let reportData: any = []
    orderReport.forEach( (eachRow: any, index: any ) => {

      let nObj: any = {}; let r = 0; 
      // console.log("orderReport: ", orderReport)
      // nObj["Amazon_order_number"] = eachRow.Amazon_order_number
      nObj["IMEI"] = eachRow.IMEI
      nObj["code"] = eachRow.response.code
      nObj["status"] = eachRow.response.code === 3000 ? eachRow.response.message : eachRow.response.error[0]
      
      reportData.push( nObj )

    }); //  End of Outer ForEach Block
    
    
    const ws = XLSX.utils.json_to_sheet( reportData );
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

  } //  End of generateXLSX
  

  return (
    <>
      <Card className="create_order_package_panel_card">
        <span className="report_card_span">Update Inbound Receivings on Creator</span>
        <span className="report_card_icon_span">
          <Tooltip placement="topLeft" title={text}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width={18}
              height={18}
              viewBox="0 0 172 172"
              style={{ fill: '#000000' }}
            >
              <g
                fill="none"
                fillRule="nonzero"
                stroke="none"
                strokeWidth={1}
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit={10}
                strokeDashoffset={0}
                fontFamily="none"
                fontWeight="none"
                fontSize="none"
                textAnchor="none"
                style={{ mixBlendMode: 'normal' }}
              >
                <path d="M0,172v-172h172v172z" fill="none" />
                <g>
                  <path
                    d="M157.66667,86c0,39.57792 -32.08875,71.66667 -71.66667,71.66667c-39.57792,0 -71.66667,-32.08875 -71.66667,-71.66667c0,-39.57792 32.08875,-71.66667 71.66667,-71.66667c39.57792,0 71.66667,32.08875 71.66667,71.66667z"
                    fill="#2196f3"
                  />
                  <path
                    d="M78.83333,78.83333h14.33333v39.41667h-14.33333zM94.95833,59.125c0,4.94142 -4.01692,8.95833 -8.95833,8.95833c-4.94142,0 -8.95833,-4.01692 -8.95833,-8.95833c0,-4.94142 4.01692,-8.95833 8.95833,-8.95833c4.94142,0 8.95833,4.01692 8.95833,8.95833z"
                    fill="#ffffff"
                  />
                </g>
              </g>
            </svg>
          </Tooltip>
        </span>

        <Row style={{ marginTop: 20, justifyContent: 'center' }}>
          {startCreation ? (
            <>
              <span
                className="close"
                onClick={() => {
                  revert();
                }}
              >
                &times;
              </span>
              {alert.map((alert: AlertsTypes, index: number) => {
                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{
                      marginTop: 11,
                      padding: '0px 10px',
                      justifyContent: 'start',
                      textAlign: 'left',
                    }}
                  >
                    <Alert
                      message={alert?.message}
                      key={index}
                      type={alert?.type}
                      icon={alertIcon === 'loading' && <LoadingOutlined />}
                      showIcon
                      banner
                      className="alerts"
                      
                    />
                  </Col>
                );
              })}
            </>
          ) : (
            <>
              <Row>
                <div className="helper" />
                <div
                  className="drop display-inline align-center"
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => handleDragOver(e)}
                  onDragEnter={(e) => handleDragEnter(e)}
                  onDragLeave={(e) => handleDragLeave(e)}
                >
                  {' '}
                  {/* //@dragover.prevent @drop="onDrop" */}
                  <div className="helper" />
                  <label v-if="!image" className="btn display-inline">
                    <section className="section">
                      <div className="drag-drop-div">
                        <p className="inner-drag-drop-div-text">
                          drag and drop or click to choose a file
                        </p>
                        <img className="img" src={Excel}></img>
                      </div>
                    </section>
                    <input
                      type="file"
                      name="image"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => {
                        
                          e.target.files &&
                            updateInboundReceivings( e.target.files[0] );
                        
                      }}
                    />
                  </label>
                </div>
              </Row>
            </>
          )}
        </Row>
      </Card>

      {reportName === 'inbound-receivings-processing' && hitReport && (
              <div>
                <Row style={{ marginTop: 20, justifyContent: 'center' }}>
                  <div style={{width: '85%'}}></div>              
                  <button className='ant-btn ant-btn-primary' onClick={generateXLSX}>Export Results</button>
                </Row>

                <Row style={{ marginTop: 20, justifyContent: 'center' }}>
                  <DataTable report={orderReport} reportNo={reportName} />
                </Row>
              </div>
            )}
    </>
  );

  //? Create Order And Package Panel
  async function updateInboundReceivings(file: File) {
    if (!allowedTypes.has(file.type)) {
      warningAlert('Only Excel Files Accepted.');
      return;
    }
    setStartCreation(true);
    const orders: any = [];
    await readXlsxFile(file).then((rows: any) => {
      // console.log("ROWs: ", rows)
      for (let i = 0; i < rows.length; i++) {

        // if( i > 10 ) { return; }
        const obj: any = {};
        for (let j = 0; j < rows[i].length; j++) {
          if (rows[i + 1]) {
            // if (rows[0][j] === 'Serial') {
            // obj[rows[0][j]] = `${rows[i + 1][j]}`;
            // } else {
            obj[rows[0][j]] = `${rows[i + 1][j]}`;
            // }
          }
        }
        if (!isEmpty(obj)) {
          orders.push(obj);
        }
      }
    });

    console.log("Receivings: ", orders)
    // return false;
    let URL = `/api/v1/zoho/zohocreator/updateinboundreceivings/`;
    axios
      .post(URL, {
        orders,
      })
      .then((response) => {
        // console.log("#330: ", response.data.response)
        setHitReport(true);
        setStartCreation(false)
        setOrderReport( response.data.response );
        setReportName("inbound-receivings-processing")
        // console.log("response: ", response)
        successAlert('Inbound receivings updation processing has been completed.');

        updateAlerts(
          'orders',
          'The processing has been completed.',
          'success'
        );

      })
      .catch((err) => {
        errorAlert(err?.response?.data?.message);
        updateAlerts('orders', 'Something went wrong...', 'error');
        // updateAlerts('package', 'Something went wrong...', 'error');
      });

    
  }

  function revert() {
    setStartCreation(false);
    setAlertIcon('loading');
    updateAlerts('order', 'Please wait, inbound receivings updation process is in progress...', 'info', true);
    
  }
  function updateAlerts(
    alert_for: string,
    message: string,
    type: 'info' | 'success' | 'warning' | 'error' | undefined,
    skip?: boolean
  ) {
    const alerts = [...alert];
    if (!skip) {
      setAlertIcon('compiled');
    }
    for (let i = 0; i < alerts.length; i++) {
      if (alerts[i].for === alert_for) {
        alerts[i].message = message;
        alerts[i].type = type;
        alerts[i].for = alert_for;
      }
    }
    setAlert(alerts);
  }
};

export default Index;
