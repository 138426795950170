import React, { useState } from 'react';
import Spinner from '../components/Spinner/FullPage.spinner';
import DataTable from '../components/Report/data.table';
import Login from '../components/Auth/login';
import { APIDetailsTypes, LoginTypes } from '../interfaces/order';
import { message, Row, Col } from 'antd';
import axios from 'axios';

import './style.scss';
import UpdateCreatorOrders from '../components/Report/amazon-orders-to-creator';
import UpdateInboundReceivings from '../components/Report/inbound-receivings-to-creator';

import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';


const successAlert = (alert: string) => {
  message.success(alert);
};
const errorAlert = (alert: string) => {
  message.error(alert);
};
const warningAlert = (alert: string) => {
  message.warning(alert);
};

const capitalizeName = (str: any) => {

  return str.replace(/\b(\w)/g, (s: any) => s.toUpperCase());

  // return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word: any, index: any) => {
  //   return index === 0 ? word.toLowerCase() : word.toUpperCase();
  // }).replace(/\s+/g, '');
}

const Landing: React.FC<APIDetailsTypes> = (props) => {
    
    const { allAPI, successAPI, failedAPI } = props;
    const [spinner, setSpinner] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [hitReport, setHitReport] = useState<any>(false);
    const [orderReport, setOrderReport] = useState<any>([]);
    const [reportName, setReportName] = useState<string>('order-processing');
    const [profitReport, setProfitReport] = useState<any>([]);
    
    let finalIMEIData: any = []
    let saleOrderIMEIs: any = []
    let purchaseOrderIMEIs: any = []
    
    
    return (
    <>
        {sessionStorage.getItem('cBsXJqYHEIsLSvYeGboCNYNj+C1jcmVKWYjDNp+WbE4=') ===
        '1' || isLogin ? (
            <>
          {spinner && <Spinner />}
          <div
            style={{
              textAlign: 'center',
              marginTop: '1em',
              marginBottom: '8em',
            }}
          >
            <div
              className="site-layout-background"
              style={{ padding: 24, marginTop: 125, minHeight: 380 }}
            >
              <Row>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                  <UpdateCreatorOrders
                      customers={() => {}}
                      customersDetail={""}
                    />
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                  <UpdateInboundReceivings
                      customers={() => {}}
                      customersDetail={""}
                    />
                </Col>
                
              </Row>
              </div>

            </div>
            
            </>
        )
        : (
            <>
              {spinner && <Spinner />}
    
              <Login
                login={(values: LoginTypes) => {
                  DoLogin(values);
                }}
              />
            </>
          )}
    </>
    );

//~ ? Remember
// .: On login again check is sales return retrive or no
// .! if not call it again
    function DoLogin(values: LoginTypes) {

        setSpinner(true);
        axios
        .post(`/api/v1/login`, values)
        .then((response) => {
            setSpinner(false);
            successAlert(response?.data?.message);
            sessionStorage.setItem(
            'cBsXJqYHEIsLSvYeGboCNYNj+C1jcmVKWYjDNp+WbE4=',
            '1'
            );
            props?.isLoggedIn();
            setIsLogin(true);
        })
        .catch((err) => {
            setSpinner(false);
            errorAlert(err?.response?.data?.message);
        });
    }   //  End of function DoLogin

  

  
}

export default Landing;
