import React, { useState } from 'react';
// import './App.less';
import 'antd/dist/reset.css'
import Landing from './pages/Landing';
import Spinner from './components/Spinner/FullPage.spinner';
import RightSideHeader from './components/Header/right_side.header';
import Login from './components/Auth/login';
import { APIDetailsTypes, LoginTypes } from './interfaces/order';
import axios from 'axios';

import './pages/style.scss'

import { Layout, Row, Col, message } from 'antd';
const { Header, Content, Footer } = Layout;

const successAlert = (alert: string) => {
  message.success(alert);
};
const errorAlert = (alert: string) => {
  message.error(alert);
};
const warningAlert = (alert: string) => {
  message.warning(alert);
};

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [apiCalls, setAPICalls] = useState<string>('0');
  const [successAPICalls, setSuccessAPICalls] = useState<string>('0');
  const [failedAPICalls, setFailedAPICalls] = useState<string>('0');

  let d = new Date()
  let thisYear = d.getFullYear();

  return (
    <div className="App">
      <Layout style={{ lineHeight: 'auto'}}>
        <Header
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
            background: '#F7F7F7',
            border: '1px solid #eee',
            // height: '40px',
            padding: '5px',
          }}
        >
          <Row className="logo">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <span className="logo-one">2ND</span>
              <span className="logo-two">LIFE</span>
              <span className="logo-three">PHONES</span>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: 'right', float: 'right' }}
            >
              {sessionStorage.getItem(
                'cBsXJqYHEIsLSvYeGboCNYNj+C1jcmVKWYjDNp+WbE4='
              ) == '1' || isLogin ? (
                <RightSideHeader
                  allAPINo={apiCalls}
                  successAPINo={successAPICalls}
                  failedAPINo={failedAPICalls}
                />
              ) : (
                <span
                  style={{ color: 'red', fontSize: '12px', fontWeight: 500 }}
                >
                  🔴
                </span>
              )}
            </Col>
          </Row>
        </Header>
        <Content
          className="site-layout"
          style={{ padding: '0 50px', background: '#FFFFFF' }}
        >
          <Landing
            allAPI={(no: string) => setAPICalls(no)}
            successAPI={(no: string) => setSuccessAPICalls(no)}
            failedAPI={(no: string) => setFailedAPICalls(no)}
            isLoggedIn={() => setIsLogin(true)}
          />
          
          {/* <Login
            login={(values: LoginTypes) => {
              DoLogin(values);
            }}
          /> */}

        </Content>
        <Footer style={{ textAlign: 'center', background: '#FFFFFF' }}>
          2ndLifePhones ©{thisYear}
        </Footer>
      </Layout>

      {/* <PageHeader
        backIcon={false}
        className="site-page-header"
        title={
          <a
            href="https://2ndlifephones.com/"
            target="_blank"
            style={{ cursor: 'pointer', color: 'black' }}
            rel="noreferrer"
          >
            2ND LIFE PHONES
          </a>
        }
        subTitle="Sales Return"
      />
      <Content style={{ padding: '0 50px' }}>
        <Routes>
          <Route path="/" element={<Landing />}></Route>
        </Routes>
      </Content>
      <Footer style={{ background: 'white' }}>
        <FooterContent />
      </Footer> */}
    </div>
  );

}
export default App;
